import useSWR, { SWRConfiguration } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

import {
  API_URL,
  superFetch,
  superFetchData,
  superFetchDataV2,
  superFetcher,
} from "@/lib/utils";

const orgService = {
  useById: (orgId: number) => {
    return useSWR(`/orgs?id=${orgId}`, superFetchData);
  },
  useByIdV2: (orgId: number) => {
    return useSWR(`/orgs/${orgId}`, (key) => {
      if (!orgId) return null;
      return superFetchDataV2(key);
    });
  },
  useUpdate: (org: any) => {
    return superFetch(`${API_URL}/orgs`, {
      method: "PUT",
      body: JSON.stringify(org),
    }) as any;
  },
  useDefaultContract: (orgId: number) => {
    return useSWR(`/orgs/contracts/default?lab_id=${orgId}`, superFetchData);
  },
  useContract: (orgId: number, type: "equipment" | "service") => {
    return useSWR(
      `/orgs/contracts?id=${orgId}&contract_type=${type}`,
      superFetchData,
    );
  },
  useSubscriptionPlans: (id: number) => {
    return useSWR(`/orgs/${id}/subscription-plans`, superFetchDataV2);
  },
  useAffiliations: (orgId: number) => {
    return useSWR(`/orgs/${orgId}/affiliations`, superFetchDataV2);
  },
  useApprovals: (key: string | null, options?: SWRConfiguration) => {
    return useSWR(
      key === null ? null : key ? `/orgs/approvals${key}` : "/orgs/approvals",

      superFetchData,
      options,
    );
  },
  useSubmitAccessRequest: (body: any) => {
    return superFetch(`${API_URL}/orgs/lab-access-registrations`, {
      method: "POST",
      body: JSON.stringify(body),
    });
  },
  useScientificProcesses: (key: string | null, options?: SWRConfiguration) => {
    return useSWR(
      key === null
        ? null
        : key
          ? `/scientific-process${key}`
          : "/scientific-process",
      superFetcher,
    );
  },
  useListingScientific: (
    key: string | null,
    options?: SWRInfiniteConfiguration,
  ) => {
    const url =
      key === null ? null : key ? `/listings/search${key}` : "/listings/search";
    return useSWRInfinite(url as any, superFetchData, options);
  },
  useBooking: (bookingCode: string, options?: SWRConfiguration) => {
    return useSWR(
      bookingCode ? `${API_URL}/bookings/${bookingCode}` : null,
      superFetch,
      options,
    );
  },
};

export default orgService;
